export const GRANT_WRITER = "grant_writer";

export const SUBSCRIPTION = "subscription";

export const CREDITS = "credits";

export const CREDIT_POOL_CREDITS = "pool_credits";

export default {
  GRANT_WRITER,
  SUBSCRIPTION,
  CREDITS,
  CREDIT_POOL_CREDITS,
};
