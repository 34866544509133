import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PlusOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import Link from "next/link";

const FEATURES_LINK = "/settings?tab=features";

function SidebarGroup({
  label,
  icon,
  children,
  showAddFeatures,
  collapsed,
  isCollapsible,
  showHeader,
  defaultOpen,
  className,
  borderTop,
}) {
  const [open, setOpen] = useState(defaultOpen || false);

  const content = (
    <div
      className={classNames(
        "flex flex-row justify-between w-full py-1.5 bg-gray-100/50 border-b border-gray-200 px-4 pl-5",
        {
          "border-t ": borderTop,
        }
      )}
    >
      <div
        className={classNames(
          "text-sm flex flex-row items-center space-x-4 pl-0.5 mr-2 text-primary"
        )}
      >
        {icon}
        <h5 className="mb-0 font-medium">{label}</h5>
      </div>
      <div className="flex flex-row !mr-[3px] space-x-1">
        {showAddFeatures && (
          <Tooltip placement="right" title="Toggle features">
            <Link href={FEATURES_LINK}>
              <div className="rounded-[0.25rem] border border-primary text-primary p-1.5 cursor-pointer transition-all duration-300 hover:bg-primary hover:text-accent active:opacity-75">
                <PlusOutlined className="text-sm" />
              </div>
            </Link>
          </Tooltip>
        )}
        {isCollapsible && (
          <div className="rounded-[0.25rem] border border-primary text-primary p-1.5 cursor-pointer transition-colors duration-300 hover:bg-primary hover:text-accent">
            {open && <UpOutlined className="text-sm" />}
            {!open && <DownOutlined className="text-sm" />}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className={classNames("flex flex-col", className)}>
      {!collapsed && !isCollapsible && showHeader && (
        <li className="flex flex-row items-center justify-between font-medium text-gray-500 pb-1">
          {content}
        </li>
      )}
      {!collapsed && isCollapsible && (
        <button
          className={`flex flex-row items-center justify-between space-x-2 
            font-medium text-gray-500 cursor-pointer transition-colors duration-300 hover:bg-accent/20`}
          onClick={() => setOpen(!open)}
        >
          {content}
        </button>
      )}
      {((isCollapsible && open) || !isCollapsible) && (
        <div
          className={classNames(
            "flex flex-col mx-3 space-y-1 pb-2 border-b border-gray-200",
            {
              "pb-1 ": isCollapsible && open,
            }
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}

SidebarGroup.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  collapsed: PropTypes.bool,
  showAddFeatures: PropTypes.bool,
  children: PropTypes.node,
  isCollapsible: PropTypes.bool,
  showHeader: PropTypes.bool,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
};

SidebarGroup.defaultProps = {
  collapsed: false,
  showAddFeatures: false,
  children: undefined,
  isCollapsible: false,
  showHeader: true,
  defaultOpen: false,
  className: undefined,
};

export default SidebarGroup;
