import { useEffect, useState } from "react";
import useMounted from "./useMounted";

export default function useIsIE() {
  const [isIE, setIsIE] = useState(false);

  const mounted = useMounted();

  useEffect(() => {
    if (mounted) {
      const ua = window.navigator.userAgent;
      if (!isIE) {
        setIsIE(/MSIE|Trident/.test(ua));
      }
    }
  }, [mounted]);

  return isIE;
}
