import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRouter } from "next/router";
import { SearchOutlined } from "@ant-design/icons";

function SearchItem({ className }) {
  const [searchText, setSearchText] = useState("");
  const router = useRouter();

  return (
    <div className={classNames("pl-4 pr-4 pb-2", className)}>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          setSearchText("");
          router.push(`/explore?q=${searchText}`);
        }}
        className="flex flex-row space-x-1 border border-primary/20 rounded-[0.25rem] py-[2px] pl-2 pr-[2px] transition-colors duration-300 focus-within:border-primary"
      >
        <input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Search for anything"
          className="w-full text-sm outline-none"
          onFocus={(event) => {
            if (event && event.target) {
              event.target.select();
            }
          }}
        />
        <button
          type="submit"
          className="rounded-[0.25rem] border border-primary p-1.5 cursor-pointer transition-all duration-300 bg-primary text-white hover:text-accent active:opacity-75"
        >
          <SearchOutlined className="text-sm" />
        </button>
      </form>
    </div>
  );
}

SearchItem.propTypes = {
  className: PropTypes.string,
};

SearchItem.defaultProps = {
  className: undefined,
};

export default SearchItem;
