import { Alert, Button, Form, Input } from "antd";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import OrgContext from "../orgContext";

const Reauthenticate = ({ onFinish }) => {
  const { user } = useContext(OrgContext);
  const [form] = Form.useForm();

  const [error, setError] = useState(null);

  const auth = getAuth();
  const { currentUser } = auth;

  useEffect(() => {
    if (user?.Email) {
      form.setFieldsValue(user);
    }
  }, [user, form]);

  const handleGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      await reauthenticateWithPopup(auth.currentUser, provider);
      onFinish();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleReauthenticate = async ({ password }) => {
    // TODO: handle Google and other providers

    const credential = EmailAuthProvider.credential(
      currentUser?.email,
      password
    );

    try {
      await reauthenticateWithCredential(auth.currentUser, credential);
      onFinish();
    } catch (err) {
      setError(err.message);
    }
  };

  const isGoogle = currentUser?.providerData[0]?.providerId === "google.com";

  return (
    <div>
      <Alert
        showIcon
        className="my-4"
        message="You need to re-login to continue"
        type="info"
      />
      {isGoogle ? (
        <button
          key="google"
          type="button"
          onClick={handleGoogle}
          className="hover:bg-gray-100 text-sm space-x-4 border justify-center flex w-full rounded-md p-3 my-4 border-gray-300 text-gray-900"
        >
          <svg
            viewBox="0 0 18 18"
            aria-hidden="true"
            style={{
              height: 20,
              width: 20,
            }}
            display="block"
          >
            <g fill="none" fillRule="evenodd">
              <path
                d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                fill="#EA4335"
              />
              <path
                d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                fill="#4285F4"
              />
              <path
                d="M3.88 10.78A5.54 5.54 0 013.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 000 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                fill="#FBBC05"
              />
              <path
                d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                fill="#34A853"
              />
              <path d="M0 0h18v18H0V0z" />
            </g>
          </svg>
          <span className="">Re-authenticate with Google</span>
        </button>
      ) : (
        <Form
          layout="vertical"
          initialValues={user}
          onFinish={handleReauthenticate}
          form={form}
        >
          <Form.Item name="password" label="Your Password" required>
            <Input
              type="password"
              placeholder="Re-enter your password"
              className="rounded"
            />
          </Form.Item>

          <Form.Item className="flex items-end text-right">
            <Button size="large" htmlType="submit" shape="round" type="primary">
              Confirm
            </Button>
          </Form.Item>
        </Form>
      )}

      {error && <Alert message={error} type="error" showIcon />}
    </div>
  );
};

Reauthenticate.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

export default Reauthenticate;
