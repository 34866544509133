const emailLooksValid = (email) => {
  if (!email) return false;
  const clean = email?.toLowerCase()?.trim();
  const badDomains = [
    "@example.",
    "@email.",
    "@test.",
    "localhost",
    "@invalid.",
  ];
  for (let i = 0; i < badDomains.length; i += 1) {
    const domain = badDomains[i];
    if (clean?.includes(domain)) {
      return false;
    }
  }
  return email?.includes("@");
};

export default emailLooksValid;
