import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Tooltip } from "antd";
import classNames from "classnames";
import { useRouter } from "next/router";

import NewBadge from "./NewBadge";
import UpgradeIcon from "./UpgradeIcon";

function SidebarItem({
  href,
  icon,
  label,
  isNew,
  upgradeFeature,
  collapsed,
  isAIFeature,
  onClick,
  isPippin,
  className,
}) {
  const router = useRouter();
  const isActive = href === router.asPath;

  let iconItem = (
    <div
      className={classNames(
        "flex flex-col items-center justify-center duration-300 transition-colors text-black h-7 w-7 group-hover:bg-primary group-hover:text-accent group-hover:border-primary rounded-full",
        { "bg-primary !border-primary text-accent": isActive }
      )}
    >
      {icon}
    </div>
  );
  let itemBody;

  if (isAIFeature) {
    iconItem = (
      <div className="flex flex-col items-center justify-center h-7 w-7 from-pink-500 to-yellow-500 bg-gradient-to-br rounded-full group">
        <div
          className={classNames(
            "flex flex-col items-center justify-center w-full h-full transition-all duration-300 text-black bg-white group-hover:bg-transparent  group-hover:text-white",
            {
              "bg-transparent !border-transparent !text-white": isActive,
            }
          )}
        >
          {icon}
        </div>
      </div>
    );
  }

  if (collapsed) {
    itemBody = (
      <Tooltip placement="right" title={label}>
        <li
          className={classNames(
            "!flex flex-row items-center justify-center cursor-pointer transition-colors duration-300 hover:bg-accent/25 py-1 group",
            className
          )}
        >
          {iconItem}
        </li>
      </Tooltip>
    );
  } else {
    itemBody = (
      <li
        className={classNames(
          "flex flex-row items-center justify-between cursor-pointer transition-all duration-300 rounded-tl-full rounded-bl-full mb-1 last:mb-0 group active:opacity-75 overflow-hidden",
          className
        )}
      >
        <div
          className={classNames("rounded-tr-md rounded-br-md w-full", {
            "bg-accent": !isAIFeature,
            "from-pink-500 to-yellow-500 bg-gradient-to-br": isAIFeature,
          })}
        >
          <div
            className={classNames(
              "flex flex-row items-center space-x-2 px-1 py-1 w-full transition-colors duration-300 bg-white group-hover:bg-white/80",
              {
                "bg-white/70": isActive,
              }
            )}
          >
            <div className="flex flex-row items-center justify-between w-full pr-0.5">
              <div className="flex flex-row items-center space-x-2 ">
                {iconItem}
                <span>{label}</span>
                {isNew && <NewBadge />}
              </div>
              {upgradeFeature && <UpgradeIcon feature={upgradeFeature} className="mr-0.5" />}
              {isPippin && (
                <img src="/pippin.png" className="h-6" alt="pippin" />
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }

  return (
    <Link href={href} onClick={onClick} className="hover:text-black">
      {itemBody}
    </Link>
  );
}

SidebarItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  upgradeFeature: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  collapsed: PropTypes.bool,
  isAIFeature: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

SidebarItem.defaultProps = {
  isNew: false,
  upgradeFeature: false,
  collapsed: false,
  isAIFeature: false,
  onClick: undefined,
  className: undefined,
};

export default SidebarItem;
