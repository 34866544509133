import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useRouter } from "next/router";
import {
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { INTERCOM_URL } from "../../config";

function HelpCentre({ onSignout, className }) {
  const router = useRouter();

  return (
    <div
      className={classNames(
        className,
        "flex items-center h-8 border-gray-400 border-opacity-50 border rounded-[0.25rem] overflow-hidden"
      )}
    >
      <a
        href={`${INTERCOM_URL}/en`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center space-x-1.5 h-full text-black border-r border-gray-300 px-2 transition-colors duration-300  hover:bg-primary hover:text-accent flex-1"
      >
        <QuestionCircleOutlined className="text-sm flex items-center" />
        <div className="text-sm">Help</div>
      </a>
      <button
        className="flex items-center justify-center space-x-1.5 h-full text-black border-r border-gray-300 px-2 transition-colors duration-300 hover:bg-primary hover:text-accent flex-1"
        onClick={() => router.push(`/settings`)}
      >
        <SettingOutlined className="text-sm flex items-center" />
        <div className="text-sm">Settings</div>
      </button>
      <button
        className="flex items-center justify-center space-x-1.5 h-full text-black border-r last:border-r-0 px-2 transition-colors duration-300 hover:bg-primary hover:text-accent flex-1"
        onClick={onSignout}
      >
        <LogoutOutlined className="text-sm flex items-center" />
        <div className="text-sm">Logout</div>
      </button>
    </div>
  );
}

HelpCentre.propTypes = {
  onSignout: PropTypes.func.isRequired,
  className: PropTypes.string,
};

HelpCentre.defaultProps = {
  className: undefined,
};

export default HelpCentre;
