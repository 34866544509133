import { useEffect, useState } from "react";
import fire from "../fire";
import {
  combineCoreDemographics,
  convertSchemaForFrontend,
} from "../methods/schema";
import { exportData } from "../methods/people";

const db = fire.firestore();

export default function useSchemaSubscribe(orgId) {
  const [schemaDetails, setSchemaDetails] = useState(null);

  const exportSchemaData = ({ data, include, exclude, extraKeyMap = {} }) => {
    if (!schemaDetails) {
      return data;
    } else {
      const { schemaLookup } = schemaDetails;

      console.log({ schemaLookup });

      const arrayData = Array.isArray(data) ? data : [data];

      return exportData({
        data: arrayData,
        schemaLookup: { ...schemaLookup, ...extraKeyMap },
        include,
        exclude,
      });
    }
  };

  useEffect(() => {
    if (orgId) {
      const schemaUnsubscribe = db
        .collection("Organisations")
        .doc(orgId)
        .collection("Schemas")
        .doc("master")
        .onSnapshot((doc) => {
          if (doc.exists) {
            const schema = doc.data();
            const { DEMOGRAPHICS, ...coreFields } = schema;
            const coreFieldsArr = convertSchemaForFrontend(coreFields);
            const demographicsArr = convertSchemaForFrontend(DEMOGRAPHICS);
            // demographic fields will have an isDemographic property
            const schemaFieldsArr = combineCoreDemographics(
              coreFields,
              DEMOGRAPHICS
            );
            // has the format {fieldId:fieldName} for looking up field names
            const schemaLookup = schemaFieldsArr.reduce((obj, field) => {
              obj[field.id] = field.name;
              return obj;
            }, {});
            // has the format {fieldName:fieldId} for looking up field names

            const reverseSchemaLookup = schemaFieldsArr.reduce((obj, field) => {
              obj[field.name] = field.id;
              return obj;
            }, {});

            setSchemaDetails({
              schema,
              coreFieldsArr,
              demographicsArr,
              schemaFieldsArr,
              schemaLookup,
              reverseSchemaLookup,
              hasSchema: true,
            });
          }
        });
      return () => schemaUnsubscribe();
    }
  }, [orgId]);

  return { ...schemaDetails, exportSchemaData };
}
