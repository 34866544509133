import React from "react";
import PropTypes from "prop-types";
import { Tooltip, Avatar } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";

function UpgradeIcon({ feature, className }) {
  return (
    <Tooltip title={`Upgrade your plan to include ${feature}`} className={className}>
      <Avatar
        size="small"
        className="bg-gradient-to-br from-blue-600 to-purple-600"
      >
        <ArrowUpOutlined />
      </Avatar>
    </Tooltip>
  );
}

UpgradeIcon.propTypes = {
  feature: PropTypes.string.isRequired,
  className: PropTypes.string,
};

UpgradeIcon.defaultProps = {
  className: undefined,
};

export default UpgradeIcon;
