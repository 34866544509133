import React, { useEffect, useState, useMemo } from "react";
import Router from "next/router";
import Link from "next/link";
import {
  LogoutOutlined,
  MenuOutlined,
  CloseOutlined,
  FolderAddOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Modal, Drawer, Alert, ConfigProvider } from "antd";
import * as Sentry from "@sentry/nextjs";
import Head from "next/head";
import {
  getAuth,
  getIdToken,
  multiFactor,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import OrgContext from "../components/orgContext";
import SentryBoundary from "../components/SentryBoundary";
import fire, { BUILD_LEVEL } from "../fire";

import "../styles/style.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import useIsIE from "../hooks/useIsIE";
import useSchemaSubscribe from "../hooks/useSchemaSubscribe";
import AppMenu from "../components/AppMenu";
import CookieConsent from "../components/CookieConsent";
import emailLooksValid from "../lib/emailLooksValid";
import { SITE_URL } from "../config";
import theme from "../theme/themeConfig";
import useMounted from "../hooks/useMounted";
import {
  ACTIVE,
  RUN_TILL_END,
} from "../features/grant-writer/constants/subscriptionState";
import { GRANT_WRITER } from "../features/grant-writer/constants/planTypes";
import TotpSetup from "../components/auth/TotpSetup";

// Blank css imported as a hack to fix nextjs problem with importing css on route changes

const db = fire.firestore();
const functions = fire.functions();

const MyApp = (props) => {
  const [loading, setLoading] = useState(true);
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const [organisation, setOrganisation] = useState(null);
  const [indivNoOrg, setIndivNoOrg] = useState(false);
  const [funder, setFunder] = useState(false);
  const mounted = useMounted();

  const [orgData, setOrgData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isIE = useIsIE();
  const [plan, setPlan] = useState(null);

  const [tagData, setTagData] = useState([]);

  const accountStatus = (org) => {
    try {
      const accountStatusRef = db.collection("AccountStatus").doc(org);

      const subscribe = accountStatusRef.onSnapshot((accountStatusDoc) => {
        const accountStatusData = accountStatusDoc?.data();

        console.log({ accountStatusData });

        let activeSubscription;

        const {
          subscriptions = {},
          credits = {
            [GRANT_WRITER]: {
              usage: {
                grants: 0,
              },
              limits: {
                grants: 0,
              },
            },
          },
        } = accountStatusData || {};

        const isBetweenNative = (start, end) => {
          const now = new Date();
          return now >= start && now <= end;
        };

        if (subscriptions && Object.values(subscriptions).length > 0) {
          activeSubscription = Object.values(subscriptions).find(
            ({ state, periodStart, periodEnd }) =>
              (state === ACTIVE || state === RUN_TILL_END) &&
              periodStart &&
              periodEnd &&
              isBetweenNative(
                periodStart?.toDate
                  ? periodStart.toDate()
                  : new Date(periodStart),
                periodEnd?.toDate ? periodEnd.toDate() : new Date(periodEnd)
              )
          );
          if (activeSubscription) {
            activeSubscription.subscriptionId = Object.keys(subscriptions).find(
              (key) => subscriptions[key] === activeSubscription
            );
          }
        }

        const shouldTrack =
          BUILD_LEVEL === "production" &&
          window?.analytics &&
          emailLooksValid(getAuth().currentUser?.email);
        if (activeSubscription) {
          const traits = {
            plan: activeSubscription.planId,
            planName: activeSubscription.planName,
          };
          if (accountStatusData?.funder) {
            traits.funder = true;
          }
          if (shouldTrack) {
            window.analytics.group(org, traits);
          }
        } else {
          const traits = {
            plan: null,
            planName: null,
          };
          if (accountStatusData?.funder) {
            traits.funder = true;
          }
          if (shouldTrack) {
            window.analytics.group(org, traits);
          }
        }
        if (accountStatusData?.funder) {
          setPlan({
            ...{
              roomBookings: {
                ...(accountStatusData?.roomBookings || {
                  inquiriesEnabled: false,
                }),
              },
            },
            ...(accountStatusData?.plan || {}),
            subscriptions,
            credits,
            stats: accountStatusData?.stats || {},
            activeSubscription,
            authenticatedDomains: accountStatusData?.authenticatedDomains || [],
          });
          setFunder(true);
        } else {
          setPlan({
            ...{
              roomBookings: {
                ...(accountStatusData?.roomBookings || {
                  inquiriesEnabled: false,
                }),
              },
            },
            subscriptions,
            credits,
            stats: accountStatusData?.stats || {},
            activeSubscription,
            authenticatedDomains: accountStatusData?.authenticatedDomains || [],
          });
          setFunder(false);
        }
      });

      return () => subscribe();
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error in accountStatus", err);
    }
  };

  const [allOrgData, setAllOrgData] = useState({});
  const [claimsUpToDate, setClaimsUpToDate] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const auth = getAuth();

  /**
   * Retrieves the current users JWT token
   *
   * @params {boolean} force - Whether or not to force-refresh this token
   * @returns {Promise<string>} string token to use.
   */
  const getBearerToken = async (force = false) => {
    const token = await getIdToken(auth.currentUser, force);
    return token;
  };

  const orgAccountData = (snapshot) => {
    try {
      const orgObj = snapshot?.docs?.reduce((obj, doc) => {
        obj[doc.id] = { ...doc.data(), _id: doc.id };
        return obj;
      }, {});
      setAllOrgData(orgObj);

      let defaultOrg;
      try {
        defaultOrg =
          orgObj[localStorage.getItem("ttsOrg")] || Object.values(orgObj)[0];
      } catch (err) {
        [defaultOrg] = Object.values(orgObj);
      }

      if (
        BUILD_LEVEL === "production" &&
        window?.analytics &&
        emailLooksValid(auth.currentUser?.email)
      ) {
        Sentry.configureScope((scope) => {
          scope.setUser({ id: auth.currentUser.uid });
          // scope.clear();
        });
        window.analytics.group(defaultOrg._id, { name: defaultOrg?.Name });
      }

      setClaimsUpToDate(false);
      setOrganisation(defaultOrg._id);
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error in orgAccountData", err);
    }
  };

  const [totpRequired, setTotpRequired] = useState(false);

  const enforce2FA = (org) => {
    try {
      if (org?.requireTwoFactor) {
        const multiFactorUser = multiFactor(auth.currentUser);
        if (!multiFactorUser.enrolledFactors.length) {
          setTotpRequired(true);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error in enforce2FA", err);
    }
  };

  useEffect(() => {
    if (organisation) {
      try {
        const subcription = accountStatus(organisation);
        const selectedOrg = allOrgData[organisation];
        if (selectedOrg?.tags) {
          selectedOrg.tags = selectedOrg.tags.sort((a, b) =>
            a.localeCompare(b)
          );
        }
        setOrgData(selectedOrg);
        enforce2FA(selectedOrg);
        setLoading(false);
        return () => subcription();
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect orgAccountData", err);
      }
    }
  }, [organisation, allOrgData?.[organisation]]);

  const [user, setUser] = useState(null);

  const [funderProgramme, setFunderProgramme] = useState(null);
  useEffect(() => {
    if (organisation) {
      try {
        const unsub = db
          .collection("FunderProgrammes")
          .where("managedBy", "==", organisation)
          .limit(1)
          .onSnapshot((querySnapshot) => {
            if (querySnapshot.docs.length > 0) {
              setFunderProgramme({
                ...querySnapshot.docs[0].data(),
                _id: querySnapshot.docs[0].id,
              });
            }
          });
        return () => unsub();
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect funderProgramme", err);
      }
    }
  }, [organisation]);

  const userAccountData = (id) => {
    try {
      return db
        .collection("User")
        .doc(id)
        .onSnapshot((data) => {
          if (data && data.data()) {
            if (
              emailLooksValid(data.data()?.Email) &&
              BUILD_LEVEL === "production"
            ) {
              window.analytics.identify(data.id, {
                name: data.data().Name,
                email: data.data().Email,
              });
            }

            setUser({
              Email: auth.currentUser?.email,
              ...data.data(),
              _id: data.id,
              getBearerToken,
            });
          } else {
            setUser({ _id: id, Email: auth.currentUser?.email });
          }
        });
    } catch (err) {
      Sentry.captureException(err);
      console.log("Error in userAccountData", err);
    }
  };

  const [dataLoadingError, setDataLoadingError] = useState(false);

  useEffect(() => {
    if (mounted) {
      try {
        onAuthStateChanged(getAuth(), (user) => {
          if (user) {
            try {
              setLoading(true);
              const userSubscribe = userAccountData(user.uid);

              const unsubscribe = db
                .collection("Organisations")
                .where(`Admin.${user.uid}`, "==", true)
                .onSnapshot(
                  (snapshot) => {
                    if (snapshot.size > 0) {
                      orgAccountData(snapshot);
                      setDataLoadingError(false);
                    } else if (snapshot.metadata.fromCache) {
                      console.log(
                        "this is from cache, probably because the connection is bad"
                      );
                      setDataLoadingError(true);
                      setIndivNoOrg(true);
                      setLoading(false);
                      setOrganisation(null);
                    } else {
                      setOrganisation(null);
                      setLoading(false);
                      setIndivNoOrg(true);
                    }
                  },
                  (error) => {
                    // ...
                    if (
                      error.code === "deadline-exceeded" ||
                      error.code === "cancelled" ||
                      error.code === "unavailable"
                    ) {
                      // this may have been blocked by a firewall/anti-virus
                      // or the user may be offline
                      console.log("might need to retry");
                      setDataLoadingError(true);
                    }
                  }
                );
              return () => {
                unsubscribe();
                userSubscribe();
              };
            } catch (err) {
              console.log("There is an error here, it is bad");
              console.log({ err });
            }
          } else {
            setLoading(false);
            setOrganisation(null);
            setOrgData({});
            setFunder(false);
            setPlan(null);
          }
        });
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect onAuthStateChanged", err);
      }
    }
  }, [mounted]);

  const [networks, setNetworks] = useState([]);
  const [volunteerAdminPages, setVolunteerAdminPages] = useState([]);
  useEffect(() => {
    if (organisation) {
      try {
        db.collection("HelpPages")
          .where("orgIds", "array-contains", organisation)
          .where("referralNetwork", "==", true)
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.size > 0) {
              setNetworks(
                docSnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  _id: doc.id,
                }))
              );
            } else {
              setNetworks([]);
            }
          });
        db.collection("HelpPages")
          .where("adminOrgIds", "array-contains", organisation)
          .get()
          .then((docSnapshot) => {
            if (docSnapshot.size > 0) {
              setVolunteerAdminPages(
                docSnapshot.docs
                  .map((doc) => ({
                    ...doc.data(),
                    _id: doc.id,
                  }))
                  .filter((item) => !item.referralNetwork)
              );
            } else {
              setVolunteerAdminPages([]);
            }
          });
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect networks", err);
      }
    }
  }, [organisation]);

  const schemaDetails = useSchemaSubscribe(organisation);

  useEffect(() => {
    if (user?._id) {
      try {
        // Update the "User" collection
        db.collection("User").doc(user._id).set(
          {
            lastLoggedIn: new Date(),
          },
          { merge: true }
        );

        // Update the "PersonalData" collection
        db.collection("PersonalData")
          .where("User", "==", user._id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // Update each document in the "PersonalData" collection
              db.collection("PersonalData").doc(doc.id).set(
                {
                  lastLoggedIn: new Date(),
                },
                { merge: true }
              );
            });
          })
          .catch((error) => {
            console.error("Error updating PersonalData documents: ", error);
          });
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect user?._id", err);
      }
    }
  }, [user?._id]);

  const { Component, pageProps } = props;

  const [collapsed, setCollapsed] = useState(false);

  const handleChangeSelected = () => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const allFeatures = [...new Set([...(orgData?.features || [])])];

  const menu = (
    <AppMenu
      onChangeSelected={handleChangeSelected}
      selectOrgId={(id) => {
        if (funder) {
          setFunder(false);
        }
        setOrganisation(id);
      }}
      theme={theme}
      details={{
        multipleAccounts: Object.values(allOrgData),
        networks,
        orgData,
        volunteerAdminPages,
        funder,
      }}
      organisation={organisation}
      features={allFeatures}
      collapsed={collapsed}
      programmeId={funderProgramme?._id}
      setCollapsed={setCollapsed}
    />
  );

  useEffect(() => {
    if (mounted && window.location !== window.parent.location) {
      try {
        if (window.Intercom) {
          window.Intercom("update", {
            hide_default_launcher: true,
          });
        }
        return () => {
          if (window.Intercom) {
            window.Intercom("update", {
              hide_default_launcher: false,
            });
          }
        };
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect window.Intercom", err);
      }
    }
  }, [mounted && window && window.Intercom]);

  useEffect(() => {
    if (organisation) {
      try {
        const updateClaims = functions.httpsCallable("users-updateClaims");
        updateClaims({
          orgId: organisation,
        })
          .then(() => getBearerToken(true)) // We force the refresh here because we've updated the claims
          .then((token) => {
            setIdToken(token);
            // console.log({ idToken: token });
            setClaimsUpToDate(true);
          })
          .catch((err) => console.log({ err }));
      } catch (err) {
        Sentry.captureException(err);
        console.log("Error in useEffect organisation", err);
      }
    }
  }, [organisation]);

  const contextValues = useMemo(
    () => ({
      theme,
      plan,
      funder,
      funderProgramme,
      idToken,
      user,
      claimsUpToDate,
      loading,
      networks,
      tagData,
      restrictedAccess: orgData?.restrictedUsers?.includes(user?._id),
      volunteerAdminPages,
      lexicon: orgData?.lexicon || {},
      allOrgIds: Object.keys(allOrgData),
      organisation: { ...orgData, _id: organisation },
      ...schemaDetails,
    }),
    [
      plan,
      funder,
      funderProgramme,
      idToken,
      user,
      claimsUpToDate,
      loading,
      networks,
      orgData,
      volunteerAdminPages,
      allOrgData,
      organisation,
      schemaDetails,
    ]
  );

  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      const tagsSnapshot = await fire
        .firestore()
        .collection("Tags")
        .where("managedBy", "==", organisation)
        .get();
      const tagsData = tagsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(tagsData);
    };

    // fetchTags();
  }, [organisation]);

  return (
    <SentryBoundary>
      <ConfigProvider theme={theme}>
        <OrgContext.Provider value={contextValues}>
          <TotpSetup
            visible={totpRequired}
            onComplete={() => setTotpRequired(false)}
          />
          <CookieConsent
            consents={[
              {
                activate: () => console.log("activate"),
              },
              {
                activate: () => console.log("activate"),
              },
            ]}
          />

          {!organisation &&
            indivNoOrg &&
            auth.currentUser &&
            !auth.currentUser?.email?.includes("@timetospare.com") &&
            mounted &&
            !window.location.href.includes("onboarding") &&
            !window.location.href.includes("widgets") &&
            !window.location.href.includes("advent") &&
            !window.location.href.includes("form-builder") &&
            !window.location.href.includes("signup") &&
            !window.location.href.includes("delivery-register") &&
            !window.location.href.includes("grant-writer") &&
            !window.location.href.includes("member") &&
            !loading && (
              <div className="fixed left-0 bottom-0 z-50">
                <Modal
                  title={
                    dataLoadingError
                      ? "Sorry, there's a problem"
                      : "Let's get you started."
                  }
                  closeIcon={false}
                  open
                  cancelButtonProps={{
                    icon: <LogoutOutlined />,
                  }}
                  okButtonProps={{
                    icon: dataLoadingError ? (
                      <ReloadOutlined />
                    ) : (
                      <FolderAddOutlined />
                    ),
                  }}
                  cancelText="Sign Out"
                  onCancel={() => {
                    signOut(auth);
                  }}
                  onOk={() => {
                    if (dataLoadingError) {
                      window.location.reload();
                    } else {
                      Router.push("/onboarding");
                    }
                  }}
                  okText={
                    dataLoadingError
                      ? "Refresh the page"
                      : "Create an Organisation"
                  }
                >
                  {dataLoadingError && (
                    <Alert
                      showIcon
                      message="Something has gone wrong"
                      type="error"
                    />
                  )}
                  {dataLoadingError ? (
                    <p className="mt-4">
                      There&apos;s an issue fetching your data. This may be your
                      internet connection, or you may have a firewall or
                      anti-virus software blocking access. Please try again, and
                      if this doesn&apos;t work, please contact us.
                    </p>
                  ) : (
                    <>
                      <div className="bg-gray-50 border border-gray-300 rounded-md p-2 my-6">
                        <div className="">
                          <p className="font-medium mb-1">
                            Already have an organisation account?
                          </p>
                          You may have used the wrong email address. You can
                          sign out and sign in again with a different email
                          address.
                        </div>
                        <div className="mt-4 mb-0">
                          <p className="font-medium mb-1">
                            Trying to join an existing organisation?
                          </p>
                          You will need to ask the organisation&apos;s
                          administrator to invite you.
                        </div>
                      </div>
                      <p className="mt-4">Otherwise, let&apos;s go!</p>
                    </>
                  )}
                </Modal>
              </div>
            )}
          {isIE && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "16px 0px",
                  backgroundColor: "red",
                  color: "white",
                  position: "absolute",
                  zIndex: 2000,
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <span className="text-lg font-medium">
                  🙈 You're using Internet Explorer, which is unsafe and
                  unsupported. Please install a safe modern browser such as{" "}
                  <a
                    href="https://www.google.com/intl/en_uk/chrome/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-400 underline"
                  >
                    Chrome
                  </a>
                  ,{" "}
                  <a
                    href="https://www.mozilla.org/en-GB/firefox/new/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-400 underline"
                  >
                    Firefox
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.microsoft.com/en-us/edge"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-400 underline"
                  >
                    Edge
                  </a>
                  .
                </span>
              </div>
            </div>
          )}
          <div key="mobilenavbar" className="mobile-nav">
            {sideBarVisible ? (
              <div className="md:hidden print:hidden">
                <div>
                  <div
                    key="header-bar"
                    className={`header-bar top-0 absolute flex items-center w-full border-b border-gray-200 ${
                      theme === "dark" ? "bg-primary" : "bg-white"
                    }`}
                    id="header-bar-mobile"
                    style={{
                      height: 50,
                      zIndex: 2,
                    }}
                  >
                    {organisation && (
                      <MenuOutlined
                        className={`ml-3 ${
                          theme === "dark" ? "text-white" : "text-black"
                        }`}
                        onClick={() => setDrawerOpen(true)}
                      />
                    )}
                    {organisation ? (
                      <Link href="/dashboard" legacyBehavior>
                        <img
                          src="/plinth-logo.svg"
                          alt="logo"
                          className="ml-4"
                        />
                      </Link>
                    ) : (
                      <a href={`https://${SITE_URL}`}>
                        <img
                          src="/plinth-logo.svg"
                          alt="logo"
                          className="ml-4"
                        />
                      </a>
                    )}
                    <Drawer
                      key="drawer"
                      className={`nav-bar top-0 left-0 text-sm fixed h-screen overflow-y-auto text-left z-20 ${
                        theme === "dark" ? "bg-primary" : "bg-white"
                      }`}
                      onClose={() => setDrawerOpen(false)}
                      width="20rem"
                      bodyStyle={{
                        backgroundColor: theme === "dark" ? "#001529" : "white",
                        padding: "0",
                      }}
                      headerStyle={{
                        zIndex: 25,
                        color: theme === "dark" ? "white" : "#000AB2",
                        backgroundColor: theme === "dark" ? "#001529" : "white",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                      }}
                      title={
                        <Link href="/dashboard" legacyBehavior>
                          <img
                            src="/plinth-logo.svg"
                            alt="logo"
                            className="ml-4"
                          />
                        </Link>
                      }
                      placement="left"
                      closable
                      closeIcon={
                        <CloseOutlined
                          className={`ml-6 text-xl ${
                            theme === "dark" ? "text-white" : "text-primary"
                          }`}
                        />
                      }
                      visible={drawerOpen}
                    >
                      {organisation?.Name && (
                        <p
                          className={`text-sm ${
                            theme === "dark" ? "text-white" : "text-black"
                          }`}
                        >
                          {organisation.Name}
                        </p>
                      )}
                      {menu}
                    </Drawer>
                  </div>
                </div>
              </div>
            ) : (
              <div className="md:hidden">
                <div>
                  <div
                    key="header-bar"
                    className="header-bar top-0 absolute flex items-center  w-full border-b border-gray-200"
                    id="header-bar-mobile"
                    style={{
                      height: 50,
                    }}
                  >
                    <img src="/plinth-logo.svg" alt="logo" className="ml-4" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="content flex">
            {organisation && sideBarVisible && (
              <>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                  />
                </Head>

                <div
                  className={`${
                    collapsed ? "md:w-16" : "md:w-80"
                  } hidden md:block fixed ${
                    theme === "dark" ? "bg-primary" : "bg-white"
                  } ${isIE ? "mt-16" : ""}`}
                  style={{
                    zIndex: 2,
                  }}
                >
                  {menu}
                </div>
              </>
            )}
            <div
              className={`flex-1 print:ml-0 print:p-4 overflow-auto ${
                !organisation || !sideBarVisible
                  ? "md:ml-0"
                  : collapsed
                  ? "md:ml-16"
                  : "md:ml-80"
              }`}
            >
              <Component
                switchOrganisation={(value) => setOrganisation(value)}
                hideSidebar={() => setSideBarVisible(false)}
                showSidebar={() => setSideBarVisible(true)}
                showHeader={!organisation}
                loading={loading}
                user={user}
                organisation={orgData}
                managedBy={organisation}
                {...pageProps}
              />
            </div>
          </div>

          <style jsx global>
            {`
              .nav-bar .ant-drawer-header {
                padding: 0px;
                height: 70px;
                display: flex;
                align-items: center;
              }

              ::selection {
                background: #ffe500 !important;
                color: #121212 !important;
              }

              .ant-menu-item-selected .add-tag > * {
                color: black !important;
              }

              @media (max-width: 737px) {
                .content {
                  margin-top: ${mounted &&
                  window.location !== window.parent.location
                    ? "0px"
                    : "50px"};
                }
              }

              .header-bar {
                top: 0px;
                position: absolute;
                height: 50px;
                display: flex;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #dbdbdb;
              }
            `}
          </style>
        </OrgContext.Provider>
      </ConfigProvider>
    </SentryBoundary>
  );
};

export default MyApp;
