import React from "react";
import * as Sentry from "@sentry/nextjs";

class SentryBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="mx-auto max-w-4xl">
            <main className="sm:flex">
              <p className="text-4xl font-bold tracking-tight text-primary sm:text-5xl">
                Error.
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l-2 sm:border-accent sm:pl-6">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-4">
                    Something went wrong
                  </h1>
                  <code className="mt-6 text-base text-gray-500">
                    {this.state.error?.message}
                  </code>
                  <p className="mt-4 text-base text-gray-500">
                    Try reloading the page or contacting us.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <a
                    href="/dashboard"
                    className="inline-flex items-center rounded-md border border-transparent bg-accent  px-4 py-2 text-sm font-medium text-primary shadow-sm hover:shadow-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Go back home
                  </a>

                  <a
                    href="#"
                    onClick={() => Sentry.showReportDialog()}
                    className="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-primary :bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Report feedback
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

export default SentryBoundary;
