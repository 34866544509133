import { Modal } from "antd";
import { signOut as fbSignOut, getAuth } from "firebase/auth";

const { confirm } = Modal;

const signOut = () => {
  const handleSignOut = async () => {
    await fbSignOut(getAuth());
    window.location.href = "/";
  };

  return confirm({
    title: "Are you sure you want to sign out?",
    onOk: handleSignOut,
    onCancel() {},
  });
};

export default signOut;
