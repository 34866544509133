export enum SubscriptionState {
  PENDING = "incomplete",
  CANCELLED = "cancelled",
  ACTIVE = "active",
  RUN_TILL_END = "run_till_end",
  PAYMENT_FAILED = "payment_failed",
  EXPIRED = "expired",
}

// For backwards compatibility, export the individual constants
export const { PENDING } = SubscriptionState;
export const { CANCELLED } = SubscriptionState;
export const { ACTIVE } = SubscriptionState;
export const { RUN_TILL_END } = SubscriptionState;
export const { PAYMENT_FAILED } = SubscriptionState;
export const { EXPIRED } = SubscriptionState;

// If you need the object format for any reason
export const SUBSCRIPTION_STATES = {
  PENDING: SubscriptionState.PENDING,
  CANCELLED: SubscriptionState.CANCELLED,
  ACTIVE: SubscriptionState.ACTIVE,
  PAYMENT_FAILED: SubscriptionState.PAYMENT_FAILED,
} as const;
