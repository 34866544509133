const faintAccentGreen = "rgba(59, 240, 132, 0.25)";
const midAccentGreen = "rgba(59, 240, 132, 0.5)";

const theme = {
  components: {
    Button: {
      defaultShadow: "0px",
      primaryShadow: "0px",
      // colorPrimary: "#3BF084",
      // color: "#014550",
      // colorInfoText: "#014550",
      algorithm: true, // Enable algorithm
      // boxShadow: "0px",
      // boxShadowSecondary: "0px",
      // boxShadowTertiary: "0px",
    },
    Menu: {
      itemSelectedBg: midAccentGreen,
      itemHoverBg: faintAccentGreen,
    },
    List: {
      itemPadding: "12px",
    },
  },
  token: {
    // fontSize: 16,
    fontFamily: "Poppins",
    colorPrimary: "#014550",
    colorInfo: "#014550",
    // boxShadow: null,
    // boxShadowSecondary: "0px",
    // boxShadowTertiary: "0px",
    defaultShadow: "0px",
    itemHoverBg: faintAccentGreen,
    itemSelectedBg: midAccentGreen,
    colorFillContentHover: "red",
    controlItemBgActive: "rgba(59, 240, 132, 0.75)",
    controlItemBgActiveHover: "rgba(59, 240, 132, 0.5)",
    controlItemBgHover: faintAccentGreen,
    colorBgMask: "rgba(1, 69, 80, 0.45)",
    colorBgTextHover: "rgba(2, 69, 80, 0.25)",
    colorInfoBg: "rgba(59, 240, 132, 0.4)",
    // colorInfoBorder: "rgba(59, 240, 132, 0.25)",
  },
};

export default theme;
