import React, { useState, useEffect } from "react";

function NewBadge() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 200);
  }, []);

  return (
    <div
      className={`transition-all rounded-lg inline-flex  ml-4 text-xs px-2 py-0.5 bg-gradient-to-br
        ${visible ? "opacity-100" : "opacity-0"}
     from-blue-500 to-pink-600 text-white font-medium`}
    >
      NEW
    </div>
  );
}

export default NewBadge;
