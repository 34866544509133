export const sortAlphabetically = (a, b) => {
  if (typeof a !== "string" || typeof b !== "string") {
    a = a.toString();
    b = b.toString();
  }
  const aName = a.toLowerCase();
  const bName = b.toLowerCase();
  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const reshapeDate = (date): Date | undefined => {
  if (date) {
    if (typeof date.toDate === "function") {
      return date.toDate();
    }
    return new Date(date);
  }
};

export const removeUndefined = <T extends object>(obj: T): T =>
  Object.keys(obj).reduce((a, b) => {
    if (obj[b] !== undefined) {
      a[b] = obj[b];
    }
    return a;
  }, {} as T);

export const stringValueExists = (value: unknown): value is string =>
  typeof value === "string" && value.trim().length > 0;

export const numberExists = (value: unknown): value is number =>
  typeof value === "number";

export const isEmptyArray = (value: unknown): boolean =>
  Array.isArray(value) && value.length === 0;

export const arrayHasValues = (value: unknown): boolean => Array.isArray(value) && value.length > 0;

export const cleanString = (value: string): string =>
  value.replaceAll(/\s+/g, " ").trim();

export const unique = <T>(arr: Array<T>): Array<T> => Array.from<T>(new Set<T>(arr));

export const depluralise = (word: string): string => {
  if (word?.endsWith("s")) {
    return word.slice(0, -1);
  }
  return word;
};

export const durationToMinutes = (duration: number): number => duration / 60000;

export default {
  sortAlphabetically,
  reshapeDate,
  stringValueExists,
  cleanString,
  depluralise,
};
