import React from "react";
import Icon from "@ant-design/icons";

const PlinthSvg = ({ ...props }) => (
  <svg
    width="196"
    height="196"
    viewBox="0 0 196 196"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M176.88 44.41L104.94 2.88C102.78 1.63 100.36 1 97.94 1C95.52 1 93.1 1.63 90.94 2.88L19 44.41C14.67 46.91 12 51.53 12 56.54V139.61C12 144.61 14.67 149.23 19 151.73L90.94 193.27C93.1 194.52 95.52 195.15 97.94 195.15C100.36 195.15 102.78 194.52 104.94 193.27L104.98 193.24L176.88 151.73C181.21 149.23 183.88 144.61 183.88 139.61V56.54C183.88 51.54 181.21 46.92 176.88 44.42M53.94 162.68V77.39L93.94 100.13V185.78L53.94 162.69V162.68ZM105.8 97.67L141.94 77.25V118.22L105.8 97.68V97.67ZM138.3 125.35L101.93 146.19V104.68L138.3 125.35ZM97.69 93.06L58.06 70.53L97.43 47.97C97.59 47.88 97.75 47.84 97.93 47.84C98.1 47.84 98.27 47.88 98.43 47.97L137.67 70.45L97.69 93.05V93.06ZM175.87 139.62C175.87 141.76 174.73 143.74 172.87 144.82L101.93 185.78V155.41L145.4 130.5C148.2 128.89 149.93 125.92 149.93 122.69V73.49C149.93 70.26 148.2 67.29 145.4 65.68L102.4 41.04C101.02 40.25 99.47 39.85 97.93 39.85C96.39 39.85 94.84 40.25 93.46 41.04L50.46 65.68C47.66 67.28 45.93 70.26 45.93 73.49V158.07L22.99 144.82C21.13 143.75 19.99 141.77 19.99 139.62V56.54C19.99 54.4 21.13 52.42 22.99 51.34L94.94 9.8C95.87 9.26 96.9 9 97.94 9C98.98 9 100.01 9.27 100.94 9.8L172.88 51.34C174.74 52.41 175.88 54.39 175.88 56.54V139.61L175.87 139.62Z"
      fill="#3BF084"
    />
  </svg>
);

const PlinthLetterLogo = (props, ref) => (
  <Icon
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    ref={ref}
    component={PlinthSvg}
  />
);

export default React.forwardRef(PlinthLetterLogo);
