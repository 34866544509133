export const BOOKINGS_COLLECTION = "Bookings";

export const EVENTS_COLLECTION = "Events";

export const DATES_COLLECTION = "Dates";

export const RULES_COLLECTION = "Rules";

export const INTERACTIONS_COLLECTION = "Interactions";

export const MEMBERS_COLLECTION = "PersonalData";

export const VOLUNTEERS_COLLECTION = "Volunteers";

export const ORGANISATIONS_COLLECTION = "Organisations";

export const SCHEMAS_COLLECTION = "Schemas";

export const PROGRAMMES_COLLECTION = "FunderProgrammes";

export const REQUESTS_COLLECTION = "Requests";

export const SHORTLIST_COLLECTION = "Shortlist";

export const HELP_PAGES_COLLECTION = "HelpPages";

export const ORDERS_COLLECTION = "Orders";

export const PAYMENTS_COLLECTION = "Payments";

export const USER_COLLECTION = "User";

export const CODES_COLLECTION = "SurveyCodes";

export const FORMS_COLLECTION = "Forms";

export const PLAN_COLLECTION = "ProductPlans";

export const ACCOUNT_STATUS_COLLECTION = "AccountStatus";

export const GRANT_FORM_COLLECTION = "GrantForms";

export const GRANT_APPLICATION_COLLECTION = "GrantApplications";

export const GRANT_PROJECT_COLLECTION = "GrantProjects";

export const PURCHASES_COLLECTION = "Purchases";

export const CREDIT_POOL_COLLECTION = "CreditPools";

export const INTEGRATIONS_COLLECTION = "Integrations";

export const VENUES_COLLECTION = "Venues";

export const FUNDING_COLLECTION = "Funding";

export const OUTCOMES_COLLECTION = "Outcomes";

export const PRIVATE_COLLECTION = "Private";

export const ORG_DATA_COLLECTION = "OrgData";

export const RESOURCES_COLLECTION = "Resources";

export const MASTER_SCHEMA_KEY = "master";

export const TAGS_COLLECTION = "Tags";

export const RELATIONSHIPS_COLLECTION = "Relationships";

export const DIRECTORIES_COLLECTION = "Directories";

export const CAPACITIES_COLLECTION = "Capacities";

export const PERMISSIONS_COLLECTION = "Permissions";

export default {
  BOOKINGS_COLLECTION,
  EVENTS_COLLECTION,
  RULES_COLLECTION,
  INTERACTIONS_COLLECTION,
  ORGANISATIONS_COLLECTION,
  PROGRAMMES_COLLECTION,
  VOLUNTEERS_COLLECTION,
  MEMBERS_COLLECTION,
  REQUESTS_COLLECTION,
  HELP_PAGES_COLLECTION,
  ORDERS_COLLECTION,
  USER_COLLECTION,
  CODES_COLLECTION,
  FORMS_COLLECTION,
  GRANT_FORM_COLLECTION,
  PURCHASES_COLLECTION,
  CREDIT_POOL_COLLECTION,
  INTEGRATIONS_COLLECTION,
  VENUES_COLLECTION,
  FUNDING_COLLECTION,
  OUTCOMES_COLLECTION,
  PRIVATE_COLLECTION,
  RESOURCES_COLLECTION,
  MASTER_SCHEMA_KEY,
  PAYMENTS_COLLECTION,
  TAGS_COLLECTION,
};
