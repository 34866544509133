import {
  getDoc,
  getDocs,
  collection,
  query,
  where,
  doc,
  writeBatch,
  arrayUnion,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import {
  MASTER_SCHEMA_KEY,
  MEMBERS_COLLECTION,
  ORGANISATIONS_COLLECTION,
  OUTCOMES_COLLECTION,
  SCHEMAS_COLLECTION,
  TAGS_COLLECTION,
} from "../../constants/collections";
import { cleanString, stringValueExists } from "../../methods/utilities";
import fire from "@/fire";
import { MemberProfile, Organisation, Outcome, Schema, SchemaField } from "@/types";
import { defaultFields, defaultInsightsFields } from "@/methods/schema";

const db = fire.firestore();

export const loadOrganisation = async (orgId: string): Promise<Organisation | null> => {
  const orgDoc = await getDoc(doc(db, ORGANISATIONS_COLLECTION, orgId));
  if (orgDoc.exists()) {
    return { ...orgDoc.data(), _id: orgDoc.id } as Organisation;
  }
  return null;
};

export const loadOrganisations = async (orgIds: string[]): Promise<Organisation[]> => {
  const existingOrgIds = orgIds.filter((orgId) => stringValueExists(orgId));
  const uniqueOrgIds = [...new Set<string>(existingOrgIds)];
  const orgs = await Promise.all(uniqueOrgIds.map((orgId) => loadOrganisation(orgId)));
  return orgs.filter((org) => org !== null);
};

export const addOrganisationTag = async (orgId: string, tagName: string, tagDescription: string) => {
  const cleanTag = cleanString(tagName);
  const batch = writeBatch(db);
  batch.update(doc(db, ORGANISATIONS_COLLECTION, orgId), {
    tags: arrayUnion(cleanTag),
  });
  batch.set(doc(collection(db, TAGS_COLLECTION)), {
    name: cleanTag,
    description: tagDescription,
    managedBy: orgId,
  });
  await batch.commit();
};

export const addAllOrganisationTags = async (orgId, tags) => {
  const batch = writeBatch(db);
  batch.update(doc(db, ORGANISATIONS_COLLECTION, orgId), {
    tags: arrayUnion(...tags.map((tag) => tag.name)),
  });
  tags.forEach((tag) => {
    batch.set(doc(collection(db, TAGS_COLLECTION)), {
      ...tag,
      managedBy: orgId,
    });
  });
  await batch.commit();
};

export const updateOrganisation = async (orgId: string, data: Partial<Organisation>) => {
  await updateDoc(doc(db, ORGANISATIONS_COLLECTION, orgId), data);
};

export const addFeature = async (orgId: string, feature: string) => {
  await updateDoc(doc(db, ORGANISATIONS_COLLECTION, orgId), { features: arrayUnion(feature) });
};

export const addOutcome = async (data: Partial<Outcome>) => addDoc(collection(db, OUTCOMES_COLLECTION), data);

export const addOutcomeTag = async (outcomeId: string, tag: string) => {
  await updateDoc(doc(db, OUTCOMES_COLLECTION, outcomeId), { tags: arrayUnion(tag) });
}

export const setOutcome = async (outcomeId: string, data: Partial<Outcome>) => {
  await setDoc(doc(db, OUTCOMES_COLLECTION, outcomeId), data, { merge: true });
};

export const removeOutcome = async (outcomeId: string) => {
  await deleteDoc(doc(db, OUTCOMES_COLLECTION, outcomeId));
};

export const loadOrgOutcomes = async (orgId: string): Promise<Outcome[]> => {
  const outcomeDocs = await getDocs(
    query(collection(db, OUTCOMES_COLLECTION), where("managedBy", "==", orgId))
  );
  return outcomeDocs.docs.map((rawDoc) => ({ ...rawDoc.data(), _id: rawDoc.id }) as Outcome);
};

export const setOrganisationSchema = async (organisationId: string, schema: Partial<Schema>) => {
  await setDoc(doc(db, ORGANISATIONS_COLLECTION, organisationId, SCHEMAS_COLLECTION, MASTER_SCHEMA_KEY), schema)
};

export const updateOrganisationSchema = async (organisationId: string, updates: Record<string, SchemaField>) => {
  await updateDoc(doc(db, ORGANISATIONS_COLLECTION, organisationId), updates);
};

export const createOrganisation = async (organisationData: Partial<Organisation>, userData: Partial<MemberProfile>) => {
  const orgRef = doc(collection(db, ORGANISATIONS_COLLECTION));
  const schemaRef = doc(db, ORGANISATIONS_COLLECTION, orgRef.id, SCHEMAS_COLLECTION, MASTER_SCHEMA_KEY);
  const memberRef = doc(collection(db, MEMBERS_COLLECTION));
  
  const batch = writeBatch(db);
  batch.set(orgRef, organisationData);
  batch.set(schemaRef, { DEMOGRAPHICS: defaultInsightsFields, ...defaultFields }, { merge: true });
  batch.set(memberRef, { ...userData, managedBy: orgRef.id }, { merge: true });
  await batch.commit();
};
