// A nice little input field for entering a TOTP code
// shows 6 boxes, each with a number
// when you type a number, it moves to the next box
// when you delete a number, it moves to the previous box
// when you type the 6th number, it calls onFinish
// when you copy/paste a 6-digit number, it fills out each box and calls onFinish

import { Input, Spin } from "antd";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

const TotpCodeInput = ({ onFinish, loading, clear, className }) => {
  // use react and AntD inputs
  // use a ref to focus the first input when the component mounts
  // use a state variable to store the 6 numbers

  const inputRefs = useRef([]);
  const [code, setCode] = useState("");

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    setCode((prev) => {
      const newCode = prev.split("");
      newCode[index] = value;
      return newCode.join("");
    });

    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text");
    if (paste.length === 6) {
      setCode(paste);
    }
  };

  useEffect(() => {
    if (code.length === 6) {
      onFinish(code);
    }
  }, [code]);

  useEffect(() => {
    if (clear) {
      setCode("");
      inputRefs.current[0].focus();
    }
  }, [clear]);

  return (
    <div className={`${className} flex justify-center items-center`}>
      {Array.from({ length: 6 }).map((_, index) => (
        <>
          <Spin spinning={loading} key={index}>
            <Input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              className="w-10 h-12 text-center ml-2 border rounded"
              value={code[index] || ""}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={handlePaste}
            />
          </Spin>
          {index === 2 && <span className="ml-2 text-xl">-</span>}
        </>
      ))}
    </div>
  );
};

TotpCodeInput.propTypes = {
  onFinish: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  clear: PropTypes.bool,
  className: PropTypes.string,
};

TotpCodeInput.defaultProps = {
  loading: false,
  clear: false,
  className: "",
};

export default TotpCodeInput;
