// frontend data has to be an array of objects (for drag and drop to work reliably), order is managed by the array, and id is stored as
// backend data has to be an object, with order stored as a property
// these functions convert between the two
// convertSchemaForFrontend should be passed in either coreFields as an object, or demographics fields as an object (since these will be separate array states on schema builder)

export const convertSchemaForFrontend = (
  fieldsObj = {},
  addRequired = false,
  schema
) => {
  const fieldsArr = [];
  Object.keys(fieldsObj)
    .sort((a, b) => fieldsObj[a].order - fieldsObj[b].order)
    .forEach((fieldId) => {
      const { type, name, options, ...rest } = fieldsObj[fieldId];

      const obj = { ...rest, id: fieldId, type, name: name || null };

      const potentialOptions =
        options ||
        schema?.[fieldId]?.options ||
        schema?.DEMOGRAPHICS?.[fieldId]?.options;
      if (potentialOptions) {
        obj.options = potentialOptions;
      }

      fieldsArr.push(obj);
    });
  return fieldsArr;
};

export const convertSchemaForBackend = (fieldsArr = []) =>
  fieldsArr.reduce((fieldsObj, currentField, index) => {
    const { id, name, type, ...rest } = currentField;
    if (currentField.isDemographic) {
      if (fieldsObj.DEMOGRAPHICS) {
        fieldsObj.DEMOGRAPHICS[id] = { ...rest, name, type, order: index };
      } else {
        fieldsObj.DEMOGRAPHICS = {
          [currentField.id]: { ...rest, name, type, order: index },
        };
      }
    } else {
      fieldsObj[id] = { ...rest, name, type, order: index };
    }
    return fieldsObj;
  }, {});

export const combineCoreDemographics = (coreFields = {}, demographics = {}) => {
  // add demographics fields onto the correct index of the combined array returned
  // Sort the fields by the order value. Then create an array based on these.
  // add a boolean isDemographic so on the way back to firebase, these can be pooled into an object again
  Object.keys(demographics).forEach((demographic) => {
    // demographics[demographic].order += Object.keys(coreFields).length;s
    demographics[demographic].isDemographic = true;
  });
  const fieldsObj = { ...coreFields, ...demographics };
  const schemaFieldsArr = convertSchemaForFrontend(fieldsObj, true);
  return schemaFieldsArr;
};

export const createFormObj = (fieldsArr) => {
  const returnFormObj = fieldsArr.reduce((formObj, field, fieldIndex) => {
    const { options, id, ...fieldObj } = field;
    if (fieldObj.name === "Full Name") {
      fieldObj.required = true;
    }
    return {
      ...formObj,
      [id]: {
        ...fieldObj,
        label: fieldObj.label || fieldObj.name,
        order: fieldIndex,
      },
    };
  }, {});

  return returnFormObj;
};

export const defaultFields = {
  "Full Name": { type: "text", name: "Full Name", order: 0 },
  Email: { type: "email", name: "Email", order: 1 },
  Phone: { type: "phoneNumber", name: "Phone", order: 2 },
  Postcode: { type: "postcode", name: "Postcode", order: 3 },
  tags: { type: "tags", name: "Tags", order: 4 },
  Address: { type: "text", name: "Address", order: 5 },
};

export const defaultInsightsFields = {
  ethnicity: {
    name: "Ethnicity",
    type: "selectAndOther",
    options: [
      "English",
      "Welsh",
      "Scottish",
      "Northern Irish",
      "Irish",
      "European",
      "Gypsy",
      "Roma",
      "Traveller",
      "British Asian",
      "Indian",
      "Pakistani",
      "Bangladeshi",
      "Chinese",
      "White & Black Caribbean",
      "White & Black African",
      "White & Asian",
      "Black British",
      "African",
      "Nigerian",
      "Congolese",
      "Somali",
      "Caribbean",
      "Moroccan",
      "Arab",
      "Kurdish",
      "Turkish",
      "Iraqi",
      "Iranian",
      "Albanian",
      "Kosovan",
      "Latin/South/Central American",
      "Other, please define",
    ],
    order: 0,
  },
  gender: {
    name: "Gender",
    options: ["Male", "Female", "Prefer to self-describe", "Prefer not to say"],
    type: "selectAndOther",
    otherText: "Prefer to self-describe",
    order: 1,
  },
  disability: {
    name: "Disability",
    type: "text",
    order: 2,
  },
  employment: {
    name: "Employment",
    options: ["Employed", "Unemployed", "Retired", "In Education"],
    type: "select",
    order: 3,
  },
  dateOfBirth: {
    name: "Date of Birth",
    type: "date",
    order: 4,
  },
};

export const defaultFieldKeys = Object.keys(defaultFields);
export const defaultInsightsFieldKeys = Object.keys(defaultInsightsFields);
