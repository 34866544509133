import React from "react";
import classNames from "classnames";

type Option = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

type SegmentedProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  buttonClassName?: string;
  className?: string;
};

function Segmented({
  options,
  value,
  onChange,
  buttonClassName,
  className,
}: SegmentedProps) {
  return (
    <div
      className={classNames(
        "flex flex-row items-center justify-between rounded-[0.20rem] border h-8 py-0.5 px-[1px] border-primary/20 space-x-0.5",
        className,
      )}
    >
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className={classNames(
            "flex flex-row items-center space-x-2 px-3 flex-shrink-0 justify-center flex-1 rounded-[0.18rem] p-1 cursor-pointer transition-all duration-300 active:opacity-75 ",
            {
              "bg-primary text-white": option.value === value,
              "hover:bg-primary/75 hover:text-white": option.value !== value,
            },
            buttonClassName,
          )}
          onClick={() => onChange(option.value)}
        >
          {option?.icon || null}
          <p className="text-sm mb-0 capitalize whitespace-nowrap">
            {option.label}
          </p>
        </button>
      ))}
    </div>
  );
}

export default Segmented;
